import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

// const GATEWAY_NAME = "mintbox"
// const SCDK_URL = "https://gateway.smallcase.com/scdk/2.0.0/scdk.js"

// const getsmallCaseToken = async () => {
//   var res = await AdminServices.getSmallcasetoken('').then((rs) => { return rs })
//   console.log("New Token =>>", res.data)
//   localStorage.setItem("smallcaseSDKToken", res.data)
//   return res.data
// }

// const userData = async() => {

//   var sdkToken = localStorage.getItem('smallcaseSDKToken')

//   if (sdkToken === null || sdkToken === undefined) {
//     sdkToken = await getsmallCaseToken()
//     console.log("hey",sdkToken)

//     return sdkToken
//   } else {
//     if (jwt_decode(sdkToken).exp * 1000 < new Date().getTime()) {
//       sdkToken = await getsmallCaseToken()
//       console.log("hey2",sdkToken)

//       return sdkToken

//     } else {
//       console.log("Token =>.", sdkToken)
//       return sdkToken

//     }
//   }

// }



// const scDKPromise = new Promise((resolve, reject) => {
//   let script = document.createElement('script')
//   document.body.appendChild(script)
//   script.onload = resolve
//   script.type = 'text/javascript'
//   script.src = SCDK_URL
// })
// scDKPromise.then(async() => {
//   var a = await userData((r) => {return r})
//   console.log(a)
//   window.sc = new window.scDK({
//     gateway: GATEWAY_NAME,
//     smallcaseAuthToken: a,
//   })
// })
// console.log("small case initialized")
// initializeSmallCase()
// serviceWorkerRegistration.unregister();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
