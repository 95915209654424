import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import { isMobileOnly } from 'react-device-detect';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from 'views/mobileView/components/atoms/ScrollToTop';
import cssVar from 'views/mobileView/styles';
import "./App.css";
// import WebHomeLayout from 'views/mobileView/route/webRoutes';
// import HomeLayout from "./views/mobileView/route";
import { lazy, Suspense } from 'react';
const HomeLayout = lazy(() => import("./views/mobileView/route"))
const WebHomeLayout = lazy(() => import("views/mobileView/route/webRoutes"))

class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			load: true,
			// smallcaseToken: '',
			// scGateway: ''
		};
	}

	changeThemeVal() {
		var theme = window.localStorage.getItem("theme")
		if (theme === null || theme === undefined) {
			window.localStorage.setItem("theme", 'light')
			document.documentElement.setAttribute("data-theme", "light")
			document.body.style = `background: ${cssVar.getPropertyValue("--bg")};`;
		} else {
			if (theme === 'dark') {
				document.documentElement.setAttribute("data-theme", "dark")
				document.body.style = `background: ${cssVar.getPropertyValue("--bg")};`;
			} else {
				document.documentElement.setAttribute("data-theme", "light")
				document.body.style = `background: ${cssVar.getPropertyValue("--bg")};`;
			}
		}
	}


	// getsmallCaseToken = async () => {
	// 	var res = await AdminServices.getSmallcasetoken('').then((rs) => { return rs })
	// 	console.log("New Token =>>", res.data)
	// 	localStorage.setItem("smallcaseSDKToken", res.data)
	// 	this.setState({ smallcaseToken: res.data })

	// 	console.log('\n starting init');
	// 	try {
	// 		await this.setState({
	// 			scGateway: new window.scDK({
	// 				gateway: 'mintbox',
	// 				smallcaseAuthToken: this.state.smallcaseToken,
	// 				config: {
	// 					// Should allow after market orders?
	// 					amo: true
	// 				}
	// 			})
	// 		})
	// 		console.log('\n init success');
	// 	} catch (err) {
	// 		console.log('\n error during init' + JSON.stringify(err.userInfo));
	// 		this.getsmallCaseToken()
	// 	}
	// }

	// smallCaseFun = async () => {

	// 	console.log('\n setting config');

	// 	var sdkToken = await localStorage.getItem('smallcaseSDKToken')
	// 	if (sdkToken === null || sdkToken === undefined) {
	// 		// console.log("hey")
	// 		this.getsmallCaseToken()
	// 	} else {
	// 		if (jwt_decode(sdkToken).exp * 1000 < new Date().getTime()) {
	// 			// console.log("hey2")
	// 			this.getsmallCaseToken()
	// 		} else {
	// 			console.log("Token =>.", sdkToken)
	// 			// this.setSmallcaseToken(sdkToken)
	// 			this.setState({ smallcaseToken: sdkToken })
	// 		}
	// 	}
	// }


	componentDidMount() {
		// this.smallCaseFun()
		this.changeThemeVal()
		this.setState({
			load: false,
		});
	}

	render() {
		if (isMobileOnly) {
			return (
				<div className="body">
					<ToastContainer />
					<Router>
						{/* <BottomNavBar/> */}
						<ScrollToTop />
						<Switch>
							<Suspense fallback={<></>}>
								<HomeLayout />
							</Suspense>
						</Switch>
					</Router>
				</div>
			);
		} else {
			return (
				<div className="body">
					<ToastContainer />
					<Router>
						{/* <BottomNavBar/> */}
						<ScrollToTop />
						<Switch>
							<Suspense fallback={<></>}>
								<WebHomeLayout />
							</Suspense>
						</Switch>
					</Router>
				</div>
			);
		}
	}
}

export default React.memo(App);
